const theme = {
  color: {
    white: '#fdfeff',
    blue: '#77a0cb',
    yellow: '#D5D110',
    grey: '#E2E1DD'
  },
  radius: {
    basic: '10px'
  },
  shadow: {
    basic: '0px 7px 10px #00000029;'
  }
}

export default theme
