import styled, { css } from 'styled-components'
import { textAlign } from 'styled-system'
import theme from '../../common/theme'

const headerFonts = css`
  font-family: 'Raleway';
  font-weight: bold;
  font-size: 3.8rem;
  margin-bottom: 17px;
  color: ${theme.color.blue};
  ${textAlign}
`

export const H1Blue = styled.h1`
  ${headerFonts}
`
export const H2Blue = styled.h2`
  ${headerFonts}
`
export const H3Blue = styled.h3`
  ${headerFonts}
`

export const H1White = styled.h1`
  ${headerFonts};
  color: ${theme.color.white};
`
export const H2White = styled.h2`
  ${headerFonts};
  color: ${theme.color.white};
`
export const H3White = styled.h3`
  ${headerFonts};
  color: ${theme.color.white};
`

export const UnderlineYellow = styled.div`
  margin-top: 10px;
  width: 277px;
  margin-left: auto;
  margin-right: auto;
  height: 0px;
  border: 3px solid ${theme.color.yellow};
  border-radius: 20px;
  @media (max-width: 1414px) {
    width: 200px;
  }
  @media (max-width: 1200px) and (min-width: 576px) {
    width: 300px;
  }
`
